<template>
  <div class="car">
    <div class="header">台山市人民医院停车位查询</div>
    <div class="bgc"></div>
    <div class="item">
      <div class="left">
        <div>
          台山市人民医院体检中心
        </div>
        <div>总车位：{{ all1 }}</div>
      </div>
      <div class="right">
        <div class="residue">
          {{ residue1 }}
        </div>
        剩余车位
      </div>
    </div>
    <div class="item">
      <div class="left">
        <div>
          台山市人民医院总院
        </div>
        <div>总车位：{{ all2 }}</div>
      </div>
      <div class="right">
        <div class="residue">{{ residue2 }}</div>
        剩余车位
      </div>
    </div>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "car",
  data () {
    return {
      all1: '',
      all2: '',
      residue1: '',
      residue2: ''
    }
  },
  created () {
    this.park()
  },
  methods: {
    async park () {
      let data = qs.stringify({
        parkSpaceId: '020303P1'
      });
      const { data: res } = await formPost(
        "/wxapp/appointmentRecord/record/parkingSpace",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        let parkData = JSON.parse(res.msg)
        this.all1 = parkData.result[0].space_total
        this.residue1 = parkData.result[0].space_num
      } else {
        this.$toast.fail(res.msg);
      }
      let data1 = qs.stringify({
        parkSpaceId: '020303P0'
      });
      const { data: res1 } = await formPost(
        "/wxapp/appointmentRecord/record/parkingSpace",
        data1
      );
      this.$toast.clear();
      if (res.code === 0) {
        let parkData = JSON.parse(res1.msg)
        this.all2 = parkData.result[0].space_total
        this.residue2 = parkData.result[0].space_num
      } else {
        this.$toast.fail(res1.msg);
      }
    },
  }
}
</script>

<style lang="less" scope>
html {
  height: 100%;
  background-color: #f8f1ff;

}

.car {
  font-size: 32px;
  padding: 0 10px;

  .item {
    background-color: #ffffff;
    margin-top: 25px;
    border-radius: 5px;
    padding: 25px 5px;
    display: flex;
    justify-content: space-between;

    .residue {
      text-align: center;
      color: #f3d26d;
      font-size: 40px;
      font-weight: 700
    }
  }
}

.header {
  width: 100%;
  text-align: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
  border-bottom: 2px solid rgb(201, 191, 191);
  font-size: 42px;
  padding: 0 20px;
}

.user {
  margin-top: 35px;
  font-size: 32px;
}
</style>
